import React from "react";
import styled from "@emotion/styled";
import { graphql, Link } from "gatsby";
import download9StepImage from "../images/download-9-STEP.jpg";
import downloadMarketResearch from "../images/whitepaper_download_market_research.jpg";
import downloadQatch from "../images/whitepaper_download_qatch.jpg";
import Layout from "../components/layout/layout";
import PageHeader from "../components/pageHeader";
import Container from "../components/container";
import Separator from "../components/Separator";
import BoxLink from "../components/boxLink";
import bgImage from "../images/featured-download-colors.png";
import rightArrow from "../images/right-arrow.png";

interface Download {
  data: {
    placeholderImg: {
      publicURL: string
    }
  }
}

const DownloadContainer = styled.div<any>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 75px;
`;

const FeaturedCard = styled.div<any>`
  width: 100%;
  border: 1px solid ${props => props.theme.borderColor};
  margin-bottom: 71px;
  background: #fff;

  h2 {
    font-weight: normal;
  }

  p {
    font-family: ${props => props.theme.headerFontFamily};
    font-size: 18px;
    line-height: 23px;
  }

  img {
    width: 100%;
    height: auto;
    object-fit: cover;
    margin-bottom: 0;

    @media (min-width: ${props => props.theme.lg}px) {
      height: 510px;
    }
  }
`;

const FeaturedTextWrapper = styled.div<any>`
  padding: 8px;
`;

const FeaturedTextWrapperInner = styled.div<any>`
  border: 1px solid ${props => props.theme.borderColor};
  padding: 20px;
  background: url(${bgImage}) no-repeat bottom right;
  background-size: 356px 165px;
  background-position: bottom -1px right -1px;
  position: relative;

  p {
    margin-bottom: 120px;
  }

  @media (min-width: ${props => props.theme.md}px) {
    padding: 40px;
    h2,
    p {
      max-width: 70%;
    }

    p {
      margin-bottom: initial;
    }

  }
`;

const FeaturedLink = styled(Link)`
  font-family: ${props => props.theme.contentFontFamily};
  font-size: 17px;
  line-height: 21px;
  text-decoration: none;
  text-transform: uppercase;
  text-align: right;
  display: inline-block;
  width: 200px;
  color: #fff;
  position: absolute;
  right: 55px;
  bottom: 31px;

  &:after {
    content: "";
    position: absolute;
    display: inline-block;
    background: url(${rightArrow}) no-repeat;
    background-size: cover;
    height: 105px;
    width: 105px;
    right: -75px;
    top: -31px;
  }
`;

const Card = styled.div<any>`
  width: 100%;

  img {
    width: 100%;
    height: auto;
    object-fit: cover;

    @media (min-width: ${props => props.theme.lg}px) {
      height: 277px;
    }
  }

  h2 {
    font-weight: normal;
  }

  p {
    font-family: ${props => props.theme.headerFontFamily};
    font-size: 18px;
    line-height: 23px;
  }

  @media (min-width: ${props => props.theme.lg}px) {
    width: 48%;
  }
`;

export default function Downloads(props: Download): JSX.Element {

  // will come from contentful
  const data = {
    downloads: [
      {
        image: download9StepImage,
        title: "How to Launch a Product",
        summary: "Your Guide to Developing Go-to-Market Strategies that Maximize Growth.",
        to: "/how-to-launch-a-product",
      },
      {
        image: downloadMarketResearch,
        title: "How to Conduct Market Research",
        summary: "Your Guide to Developing a Norm Breaking Market Research Strategy.",
        to: "/how-to-conduct-market-research",
      },
      {
        image: downloadQatch,
        title: "Qatch Case Study",
        summary: "How Strategica Partners Helped Qatch Maximize Growth And Increased Website Conversion Rate By 20%.",
        to: "/qatch-case-study",
      },
    ]
  }

  return (
    <Layout title="Blog" >
      <PageHeader
        title="Downloads"
        titleSubtext="Strategica Download Library"
        noSeperator
      />
      <Container>
        <DownloadContainer>
          {data.downloads.map((download, i) => {
            if (i == 0) {
              return (
                <FeaturedCard key={i} >
                  <Link to={download.to}><img src={download.image} alt={download.title}/></Link>
                  <FeaturedTextWrapper>
                    <FeaturedTextWrapperInner>
                      <h2>Featured Download: {download.title}</h2>
                      <p>{download.summary}</p>
                      <FeaturedLink to={download.to}>Click Here to Download Now</FeaturedLink>
                    </FeaturedTextWrapperInner>
                  </FeaturedTextWrapper>
                </FeaturedCard>
              )
            }

            if (download.summary.length > 150) {
              download.summary = `${download.summary.substring(0, 150)}...`;
            }

            return (
              <Card key={i}>
                <Link to={download.to}><img src={download.image} alt={download.title}/></Link>
                <h2>{download.title}</h2>
                <p>{download.summary}</p>
                <BoxLink to={download.to}>Download</BoxLink>
                <Separator margin="30px auto 46px 0" width="96px"/>
              </Card>
            )
          })}
        </DownloadContainer>
      </Container>
    </Layout>
  )
}

export const query = graphql`
{
  placeholderImg: file(relativePath: {eq: "download-placeholder.png"}) {
    publicURL
  }
}
`;